import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  ConfigProvider,
  DatePicker,
  Divider,
  Flex,
  Input,
  Pagination,
  Radio,
  Select,
  Statistic,
  Table,
  TimeRangePickerProps,
} from 'antd';
import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import PageWrapper from '../../components/common/PageWrapper';
import { DataContent, User, UserCount, UserInput } from '../../types/UserTypes';
import { APIResponse, RES_CD } from '../../types/InterfaceTypes';
import api, { APIAdminPath } from '../../apis/clients/ApiClient';
import PageTitle from '../../components/common/PageTitle';
import { statisticFormatter } from '../../utils/formatter';
import dayjs from 'dayjs';
import { RangeValue } from '../../types/Types';
import 'dayjs/locale/ko';

dayjs.locale('ko');

const InfoUser: React.FC = () => {
  const [queryStr, setQueryStr] = useState('');
  const [pagination, setPagination] = useState({ page: 1, size: 20 });
  const [total, setTotal] = useState(0);
  const [numberOfElements, setNumberOfElements] = useState(0);
  const [param, setParam] = useState<UserInput>({ page: 1, keyword: '' });
  const [dataList, setDataList] = useState<User[]>([]);
  const [dates, setDates] = useState<RangeValue>(null);
  const [userCount, setUserCount] = useState(0);
  const [lsUserCount, setLsUserCount] = useState(0);
  const [statusOption, setStatusOption] = useState(undefined);

  const fetchUserCount = useCallback(async () => {
    const response = await api.get<APIResponse<UserCount>>(APIAdminPath.USER_COUNT, {});
    if (response.data.code === RES_CD.R0000) {
      setUserCount(response?.data?.data?.total || 0);
      setLsUserCount(response?.data?.data?.ls || 0);
    }
  }, []);

  const fetch = useCallback(async () => {
    const response = await api.get<APIResponse<DataContent>>(APIAdminPath.USER, { params: param });
    if (response.data.code === RES_CD.R0000) {
      const mergedDataList = response?.data?.data?.content.map((user) => ({
        ...user.avatar,
        ...user,
      }));
      setTotal(response?.data?.data?.totalElements || 0);
      if (mergedDataList) {
        setDataList(mergedDataList);
      }
    }
  }, [param]);

  const onFetch = useCallback(
    (page: number, size: number) => {
      const p = {
        page: page,
        total: total,
      } as UserInput;

      if (statusOption && queryStr) {
        p.keyword = statusOption;
        p.value = queryStr;
      }

      if (dates?.length === 2) {
        p.start = dates[0]?.format('YYYYMMDD');
        p.end = dates[1]?.format('YYYYMMDD');
      }

      setParam(p);
    },
    [dates, statusOption, queryStr, total]
  );

  useEffect(() => {
    fetch();
    fetchUserCount();
  }, [param, fetch]);

  const handlePageChange = useCallback(
    (page: number, pageSize: number) => {
      setPagination({ page, size: pageSize });
      onFetch(page, pageSize);
    },
    [onFetch]
  );

  const onChangeDate = useCallback((dates: RangeValue, dateStrings: string[]) => {
    setDates(dates);
  }, []);

  const onChangeStatusOption = useCallback((value: any) => setStatusOption(value), []);

  const options = [
    { label: '전체', value: 'total' },
    { label: '순번', value: 'id' },
    { label: '이메일', value: 'email' },
    { label: '닉네임', value: 'nickname' },
    { label: '아이디', value: 'username' },
  ];

  const rangePresets: TimeRangePickerProps['presets'] = [
    { label: '일주일', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: '보름', value: [dayjs().add(-15, 'd'), dayjs()] },
    { label: '한달', value: [dayjs().add(-1, 'M'), dayjs()] },
    { label: '분기', value: [dayjs().add(-3, 'M'), dayjs()] },
    { label: '반년', value: [dayjs().add(-6, 'M'), dayjs()] },
    { label: '일년', value: [dayjs().add(-1, 'year'), dayjs()] },
  ];

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        title: '회원번호',
        dataIndex: 'id',
        key: 'id',
        width: 90,
        align: 'right',
      },
      { title: '아이디', dataIndex: 'username', key: 'username', width: 150 },
      { title: '닉네임', dataIndex: 'nickname', key: 'nickname', width: 150 },
      { title: '이메일', dataIndex: 'email', key: 'email', width: 200 },
      {
        title: '가입일',
        dataIndex: 'dateCreated',
        key: 'dateCreated',
        render: (value: any) => dayjs(value).format('YYYY.MM.DD HH:mm:ss'),
        width: 160,
      },
      {
        title: '최근접속기록',
        dataIndex: 'lastConnected',
        key: 'lastConnected',
        render: (value: any) => dayjs(value).format('YYYY.MM.DD HH:mm:ss'),
        width: 160,
      },
      { title: '출석일수', dataIndex: 'loginDays', key: 'loginDays', width: 90 },
      { title: '게시글', dataIndex: 'articleCount', key: 'articleCount', width: 90 },
      { title: '글댓글', dataIndex: 'noteCount', key: 'noteCount', width: 90 },
      { title: '보유배지', dataIndex: 'badgeCount', key: 'badgeCount', width: 90 },
      {
        title: 'LS연동일자',
        dataIndex: 'dateLsconnected',
        key: 'dateLsconnected',
        render: (value: any) => (value ? dayjs(value).format('YYYY.MM.DD HH:mm:ss') : ''),
        width: 150,
      },
      {
        title: '인플루언서',
        dataIndex: 'influencer',
        key: 'influencer',
        render: (value: boolean) => <input type="checkbox" checked={value} disabled />,
        width: 110,
      },
    ],
    []
  );

  return (
    <PageWrapper>
      <PageTitle title={'회원 정보 조회'} icon={<UserOutlined />} />
      <Flex gap={10} style={{ height: '100px' }}>
        <Card style={{ width: '100%' }}>
          <Statistic title="총가입자수" value={userCount} formatter={statisticFormatter} />
        </Card>
        <Card style={{ width: '100%' }}>
          <Statistic title="LS증권 계좌 연동 수" value={lsUserCount} formatter={statisticFormatter} />
        </Card>
      </Flex>
      <Card>
        <Flex justify="space-between" align="end">
          <Flex gap={30}>
            <Flex vertical gap={0}>
              <span style={{ lineHeight: '32px' }}>가입일</span>
              <Radio.Group optionType="button" buttonStyle="solid" />
              <DatePicker.RangePicker value={dates} onChange={onChangeDate} presets={rangePresets} />
            </Flex>

            <Divider type="vertical" style={{ height: '5em' }} />
            <Flex vertical gap={10}>
              <span>키워드 구분</span>
              <Select
                style={{ width: 120 }}
                allowClear
                placeholder={'전체'}
                value={statusOption}
                options={options}
                onChange={onChangeStatusOption}
              />
            </Flex>
            <Flex vertical gap={10}>
              <span style={{ width: 200 }}>검색어</span>
              <Input
                placeholder="검색어 입력"
                value={queryStr}
                allowClear
                onChange={(e) => {
                  setQueryStr(e.target.value);
                }}
                // onSearch={() => onFetch(pagination.page, pagination.size)}
              />
            </Flex>
          </Flex>
          <Button icon={<SearchOutlined />} onClick={() => onFetch(pagination.page, pagination.size)}>
            조회
          </Button>
        </Flex>
      </Card>
      <Flex style={{ width: '100%', minHeight: 500 }} vertical justify="center" align={'center'} gap={15}>
        <Table
          style={{ width: '100%', height: '100%' }}
          pagination={{ position: ['none'], pageSize: pagination.size }}
          bordered
          columns={columns}
          dataSource={dataList}
          rowKey={(row) => row.id}
          sticky
          title={() => <Flex> 전체 목록 {total}명</Flex>}
          footer={() => (
            <Flex justify="center">
              <Pagination
                className="ant-table-pagination-center"
                total={total}
                showSizeChanger={false}
                showTotal={(total, range) => `총 ${total}개 중 ${range[0]}~${range[1]}`}
                defaultPageSize={pagination.size}
                defaultCurrent={pagination.page}
                pageSize={pagination.size}
                current={pagination.page}
                onChange={handlePageChange}
              />
            </Flex>
          )}
        />
      </Flex>
    </PageWrapper>
  );
};
export default InfoUser;
