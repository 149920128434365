import { createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatusCode } from 'axios';
import api, { APIAdminPath } from '../clients/ApiClient';
import { APIResponse, RES_CD } from '../../types/InterfaceTypes';

/**
 * Admin Controller Fetch : 로그인
 */
const fetch_signIn = createAsyncThunk(
  '/login',
  async (input: { username: string; password: string; otpValue: string }, thunkAPI) => {
    try {
      const response = await api.post<APIResponse<any>>(APIAdminPath.SIGN_IN, input);
      if (response.status !== HttpStatusCode.Ok || response.data.code !== RES_CD.R0000) {
        return thunkAPI.rejectWithValue(response.data);
      }
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
export default fetch_signIn;
