import React from "react";
import CountUp from "react-countup";
import dayjs from "dayjs";
import { valueType } from "antd/es/statistic/utils";

export const statisticFormatter = (value: valueType): React.ReactNode => (
  <CountUp
    end={Number(value)}
    separator=","
    duration={3}
    start={0}
    style={{ fontSize: 30 }}
  />
);
/**
 * Function to format a number as a string.
 *
 * @param {number|string} val - The number to be formatted.
 * @returns {string} - The formatted number as a string.
 */
export const formatterNumber = (val: number | string) => {
  if (!val) return 0;
  return `${val}`
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    .replace(/\.(?=\d{0,2}$)/g, ".");
};

/**
 * Format phone number
 *
 * @param {string} phoneNumber - The phone number to format.
 * @returns {string} The formatted phone number.
 */
export const formatPhoneNumber = (phoneNumber: string | undefined | null) => {
  if (!phoneNumber) return "";
  const partOne = phoneNumber.substring(0, 3);
  const partTwo = phoneNumber.substring(3, 7);
  const partThree = phoneNumber.substring(7, 11);
  return partOne + "-" + partTwo + "-" + partThree;
};

export const formatDate = (
  date: string | undefined | null,
  inputFormat: string = "YYYYMMDD"
): string => {
  if (!date) return "";
  return dayjs(date, inputFormat).format("YYYY-MM-DD");
};

export const formatTime = (
  time: string | undefined | null,
  inputFormat: string = "HH:mm:ss"
) => {
  if (!time) return "";
  return dayjs(time, "HHmmss").format(inputFormat);
};

export const formatterDateMCA = (
  date: string,
  format: string = "YYYY-MM-DD HH:mm:ss"
) => {
  if (!date || !Number(date)) return "";
  return dayjs(date, "YYYYMMDDHHmmssSSS").format(format);
};

export const formatterDateMCA2 = (
  date: string | undefined | null,
  format: string = "YYYY-MM-DD HH:mm:ss"
) => {
  if (!date || !Number(date)) return "";
  return dayjs(date, "YYYYMMDDHHmmss").format(format);
};

export const formatterTimestamp = (
  timestamp: string,
  format: string = "YYYY-MM-DD HH:mm:ss"
) => {
  if (!timestamp || !Number(timestamp)) return "";
  return dayjs.unix(parseInt(timestamp)).format(format);
};
