import React from "react";
import { Divider, Flex } from "antd";

const PageTitle: React.FC<{
  title: string;
  icon?: React.ReactNode;
  rightElement?: React.ReactNode;
  isLine?: boolean;
}> = ({ title, icon, rightElement, isLine = true }) => {
  return (
    <>
      <Flex justify={"space-between"} align={"center"}>
        <Flex gap={10} style={{ fontSize: 20, fontWeight: 500 }}>
          {icon ? icon : null}
          {title}
        </Flex>
        {rightElement}
      </Flex>
      {isLine && <Divider style={{ margin: 5 }} />}
    </>
  );
};

export default React.memo(PageTitle);
