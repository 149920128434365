import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import '../../assets/styles/login.scss';
import { useAppDispatch } from '../../store/store';
import { Spin } from 'antd';
import { notification } from '../../components/common/AntdUtil';
import fetch_signIn from '../../apis/fetchs/Fetch_signIn';
import { APIResponse } from '../../types/InterfaceTypes';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

const Home: React.FC = () => {
  const dispatch = useAppDispatch();
  const [id, setId] = useState('');
  const [pw, setPw] = useState('');
  const [otp, setOtp] = useState('');
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  // const onVerify = useCallback((token: string) => {
  //   setGoogleToken(token);
  // }, []);

  const onChangeId = (e: { target: { value: React.SetStateAction<string> } }) => setId(e.target.value);
  const onChangePw = (e: { target: { value: React.SetStateAction<string> } }) => setPw(e.target.value);
  const onChangeOtp = (e: { target: { value: React.SetStateAction<string> } }) => setOtp(e.target.value);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();

  const openNotification = useCallback((msg: string) => {
    notification.error({
      message: `Error`,
      description: msg,
      placement: 'bottomRight',
    });
  }, []);

  const onLoginHandler = useCallback(
    (e: any) => {
      e.preventDefault();
      fetchSignIn(id, pw, otp);
    },
    [id, pw, otp]
  );

  const fetchSignIn = useCallback((id: string, pw: string, otp: string) => {
    dispatch(
      fetch_signIn({
        username: id,
        password: pw,
        otpValue: otp,
      })
    )
      .unwrap()
      .then((res: APIResponse<any>) => {
        if (res.code !== 'R0000') {
          setErrorMsg(res.message);
          openNotification(res.message || '');
        }
      })
      .catch((e: any) => {
        console.log('error: ', e);
        setErrorMsg('오류가 발생 했습니다.');
      })
      .finally(() => {
        setRefreshReCaptcha(true);
      });
  }, []);

  const [mp4, setVideo] = useState('/videos/1.mp4');

  useLayoutEffect(() => {
    const idx = Math.floor(Math.random() * 11) + 1;
    setVideo(`/videos/${idx.toString()}.mp4`);
  }, []);

  return (
    <>
      {/*<GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />*/}
      <div className={'container body'}>
        <div className="bg-video">
          {/*<video className="bg-video__content" autoPlay={true} muted={true} loop={true}>*/}
          {/*  <source src={mp4} type="video/mp4" />*/}
          {/*</video>*/}
        </div>
        <div className={'screen'}>
          <div className={'screen__content'}>
            <form className={'login'}>
              <div className={'login__field'}>
                <i className={'login__icon fas fa-user'}></i>
                <input
                  type="text"
                  className={'login__input'}
                  placeholder={'Admin ID'}
                  autoComplete="username"
                  required
                  maxLength={20}
                  minLength={5}
                  onChange={onChangeId}
                  value={id}
                />
              </div>
              <div className={'login__field'}>
                <i className={'login__icon fas fa-lock'}></i>
                <input
                  type="password"
                  className={'login__input'}
                  placeholder={'Password'}
                  autoComplete="current-password"
                  required
                  minLength={8}
                  maxLength={50}
                  onChange={onChangePw}
                  value={pw}
                />
              </div>
              <div className={'login__field'}>
                <i className={'login__icon fas fa-lock'}></i>
                <input
                  type="password"
                  className={'login__input'}
                  placeholder={'OTP'}
                  autoComplete={'new-password'}
                  required
                  minLength={6}
                  maxLength={6}
                  onChange={onChangeOtp}
                  value={otp}
                />
              </div>

              <button className={'button login__submit'} onClick={onLoginHandler}>
                <span className={'button__text'}>로그인</span>
                <i className={'button__icon fas fa-chevron-right'}></i>
              </button>
            </form>
            <div className={'social-login'}>
              <h3>롱숏클럽 Admin</h3>
            </div>
          </div>

          <div className={'screen__background'}>
            <span className={'screen__background__shape screen__background__shape4'}></span>
            <span className={'screen__background__shape screen__background__shape3'}></span>
            <span className={'screen__background__shape screen__background__shape2'}></span>
            <span className={'screen__background__shape screen__background__shape1'}></span>
          </div>
        </div>
      </div>
    </>
  );
};

export const Login: React.FC = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 100);
  }, []);

  return <>{show ? <Home /> : <Spin size="large" />}</>;
};
export default Login;
